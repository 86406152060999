<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br /><br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3>DOPPLER OBSTÉTRICO</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento }}
          <p></p>
          <p></p>
        </h4>
        <p v-else-if="elemento === 'espaço'"></p>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  mounted() {
    let folha = [];
      
    //   if (this.laudo.dataNascimento)
    //     folha.push(`Data de nascimento : ${this.dataFormatada(this.laudo.dataNascimento)}.`);
    //     if (this.laudo.anos || this.laudo.meses)
    //     folha.push(`${this.laudo.anos?this.laudo.anos + (this.laudo.anos > 1 ? ' anos':' ano'):''}
    //     ${this.laudo.anos && this.laudo.meses? ' e ':''}
    //     ${this.laudo.meses?this.laudo.meses + (this.laudo.meses > 1 ? ' meses':' mês'):''}
      
    //     .`);
    //     if (this.laudo.peso)
    //     folha.push(`${this.laudo.peso?"Peso: " + this.laudo.peso + ' kg.':""}${
    //   this.laudo.altura?" Altura: " + this.laudo.altura + ' cm.':""}${
    //   this.laudo.imc?" IMC: " + this.laudo.imc :""}.`);
    //   if (this.laudo.origemRacial)
    //     folha.push(`Origem racial: ${this.laudo.origemRacial}.`);
    //     if (this.laudo.metodoConcepcao)
    //     folha.push(`Método de concepção: ${this.laudo.metodoConcepcao}.`);
    //     if (this.laudo.fuma)
    //     folha.push(`Fumar durante a gravidez: ${this.laudo.fuma}.`);
    //     if (this.laudo.hipertensao)
    //     folha.push(`Hipertensão crônica: ${this.laudo.hipertensao}.`);
    //     if (this.laudo.diabeteTipoUm)
    //     folha.push(`Diabetes tipo I: ${this.laudo.diabeteTipoUm}.`);
    //     if (this.laudo.diabeteTipoDois)
    //     folha.push(`Diabetes tipo II: ${this.laudo.diabeteTipoDois}.`);
    //     if (this.laudo.lupus)
    //     folha.push(`Lúpus eritematoso sistêmico:  ${this.laudo.lupus}.`);
    //     if (this.laudo.sindromeAnti)
    //     folha.push(`Síndrome antifosfolipídica:  ${this.laudo.sindromeAnti}.`);
    //     if (this.laudo.historicoFamiliarDiabetes)
    //     folha.push(`História familiar de diabetes:  ${this.laudo.historicoFamiliarDiabetes}.`);
    //     if(this.laudo.historiaObstetricaAnterior){

    //     if(this.laudo.historiaObstetricaAnterior.length>0)
    //     folha.push("História obstétrica anterior")
    //     for (let j = 0; j < this.laudo.historiaObstetricaAnterior.length; j++) {
    //       folha.push(this.laudo.historiaObstetricaAnterior[j]+'.');
    //     }
    //   }
    //   if(this.laudo.perdasGestacionais){
    //     if(this.laudo.perdasGestacionais.length>0)
    //     folha.push("Perdas gestacionais")
    //     for (let k = 0; k < this.laudo.perdasGestacionais.length; k++) {
    //       folha.push(this.laudo.perdasGestacionais[k]+'.');
         
    //     }
    //   }
    //   if (folha.length > 0) folha.splice(0, 0, 'História materna');
    // this.historiaMaterna = [...folha];
    // folha = [];

    if (this.laudo.indicePulsatilidadeArteriaUmbilical || this.laudo.percentil)
      folha.push(
        `${
          this.laudo.indicePulsatilidadeArteriaUmbilical
            ? 'Índice de pulsatilidade: '
            : ''
        }${
          this.laudo.indicePulsatilidadeArteriaUmbilical
            ? this.laudo.indicePulsatilidadeArteriaUmbilical
            : ''
        }${
          this.laudo.percentil && this.laudo.indicePulsatilidadeArteriaUmbilical
            ? ' - '
            : ''
        }${this.laudo.percentil ? 'Percentil ' : ''}${
          this.laudo.percentil ? this.laudo.percentil : ''
        }${
          this.laudo.indiceOpcao3
            ? ' ' + this.laudo.indiceOpcao3 + ' para idade gestacional.'
            : '.'
        }`
      );
    if (this.laudo.diastole) folha.push(`Diástole: ${this.laudo.diastole}.`);
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');
    folha.push('espaço');

    if (folha.length > 0) folha.splice(0, 0, 'Artéria umbilical');
    this.arteriaUmbilical = [...folha];
    folha = [];

    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push(
        `Índice de pulsatilidade: ${
          this.laudo.indicePulsatilidadeArteriaCerebralMedia
        }${
          this.laudo.percentil3 &&
          this.laudo.indicePulsatilidadeArteriaCerebralMedia
            ? ' - '
            : ''
        }${this.laudo.percentil3 ? ' Percentil ' : ''}${
          this.laudo.percentil3 ? ' ' + this.laudo.percentil3 : ''
        } ${this.laudo.indiceOpcao1} para idade gestacional.`
      );
    if (this.laudo.opcaoVelocidade)
      folha.push(
        ` Pico da velocidade sistólica: ${this.laudo.opcaoVelocidade} cm/s, correspondendo a ${this.laudo.opcaoVelocidade2} múltiplos da mediana.`
      );
    if (this.laudo.indicePulsatilidadeRelacaoCerebroPla)
      folha.push(
        `Relação cérebro-placentária: ${
          this.laudo.indicePulsatilidadeRelacaoCerebroPla
        }${
          this.laudo.percentil2 &&
          this.laudo.indicePulsatilidadeRelacaoCerebroPla
            ? ' - '
            : ''
        }${this.laudo.percentil2 ? ' Percentil ' : ''}${
          this.laudo.percentil2 ? this.laudo.percentil2 : ''
        }${
          this.laudo.indiceOpcaoCerePlac
            ? ' ' + this.laudo.indiceOpcaoCerePlac + ' para idade gestacional.'
            : '.'
        }`
      );
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaCerebralMedia)
      folha.push('espaço');
    if (folha.length > 0) folha.splice(0, 0, 'Artéria cerebral média');
    this.arteriaCerebralMedia = [...folha];
    folha = [];

    if (this.laudo.indicePulsatilidadeArteriaUterinaD)
      folha.push(
        `Índice de Pulsatilidade (direita): ${this.laudo.indicePulsatilidadeArteriaUterinaD}.`
      );
    if (this.laudo.indicePulsatilidadeArteriaUterinaE)
      folha.push(
        `Índice de Pulsatilidade (esquerda): ${this.laudo.indicePulsatilidadeArteriaUterinaE}.`
      );
    if (this.laudo.indicePulsatilidadeArteriaUterinaD)
      folha.push(
        `Índice de Pulsatilidade médio: ${
          this.laudoSelecionado.laudoJson.camposExibidos
            .exibeIndicePulsatilidadeArteriaUterinaE2
            ? this.mediaIndice
            : ''
        }${
          this.laudo.percentilArtMed
            ? ' - Percentil ' +
              this.laudo.percentilArtMed +
              (this.laudo.indiceOpcaoArtMed
                ? ' ' + this.laudo.indiceOpcaoArtMed + ' para idade gestacional'
                : '')
            : ''
        }.`
      );
      if (this.laudo.comentariosAdcionaisMeio2)
      folha.push(
        `${this.laudo.comentariosAdcionaisMeio2}`
      );
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');
    if (this.laudo.indicePulsatilidadeArteriaUterinaD) folha.push('espaço');

    if (folha.length > 0) folha.splice(0, 0, 'Artéria uterina');
    this.arteriaUterinaDireita = [...folha];
    folha = [];

    // if (this.laudo.indicePulsatilidadeArteriaUterinaE)
    //   folha.push(
    //     `Índice de pulsatilidade: ${this.laudo.indicePulsatilidadeArteriaUterinaE
    //     }${this.laudo.percentilArtEsq && this.laudo.indicePulsatilidadeArteriaUterinaE? ' - ':''
    //   }${this.laudo.percentilArtEsq? ' Percentil ':''
    //   }${this.laudo.percentilArtEsq? this.laudo.percentilArtEsq:''
    //   }${this.laudo.indiceOpcao3? " "+this.laudo.indiceOpcao3 + ' para idade gestacional.' :'.'}`
    //   );
    // if (folha.length > 0) folha.splice(0, 0, 'Artéria Uterina esquerda');
    // this.arteriaUterinaEsquerda = [...folha];
    // folha = [];

    if (this.laudo.ductoVenoso && this.laudo.ductoVenoso == 'Ducto Venoso')
      folha.push(
        `Índice de pulsatilidade: ${this.laudo.indicePulsatilidadeDuctoVenoso} ${this.laudo.indiceOpcao4} para idade gestacional.`
      );
    if (folha.length > 0) folha.splice(0, 0, 'Ducto Venoso');
    this.ductoVenoso = [...folha];
    folha = [];
    if (this.laudo.checkFluxoUmb)
      folha.push(`Fluxo umbilical normal para a idade gestacional.`);
    if (this.laudo.checkAusFet) folha.push(`Ausência de centralização fetal.`);
    if (this.laudo.checkRelCerNormal)
      folha.push(
        `Relação cerebro-placentária normal para a idade gestacional.`
      );
    if (this.laudo.checkFluxoUmbRes)
      folha.push(`Fluxo umbilical com resistência elevada.`);
    if (this.laudo.checkRelCerAcima)
      folha.push(
        `Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar.`
      );
    if (this.laudo.checkCentraFet) folha.push(`Centralização fetal.`);
    if (this.laudo.checkDucto)
      folha.push(`Ducto venoso ${this.laudo.ductoVenNormAlt}.`);
    if (this.laudo.checkIndiceAcima)
      folha.push(
        `Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce.`
      );
    if (this.laudo.checkIndiceDentro)
      folha.push(
        `Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce.`
      );
    if (this.laudo.checkPicoAnemLeve)
      folha.push(
        `Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve.`
      );
    if (this.laudo.checkPicoAnemMod)
      folha.push(
        `Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada.`
      );
    if (this.laudo.checkCentraFet)
      folha.push(
        `Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave`
      );

    if (this.laudo.fluxoUmbilicalOpcao1)
      folha.push(
        `Fluxo umbilical ${this.laudo.fluxoUmbilicalOpcao1} para a idade gestacional, com diástole ${this.laudo.fluxoUmbilicalOpcao2}.`
      );
    if (this.laudo.centralizacaoFetal)
      folha.push(`${this.laudo.centralizacaoFetal} de centralização fetal.`);
    if (this.laudo.relacaoCerebroPlacentaria)
      folha.push(
        `Relação cérebro-placentária ${this.laudo.relacaoCerebroPlacentaria} para idade gestacional`
      );
    if (
      this.laudo.ductoVenosoIndice &&
      this.laudo.ductoVenosoIndice == 'Ducto venoso com Índice de pulsatilidade'
    )
      folha.push(
        ` Ducto venoso com Índice de pulsatilidade ${this.laudo.indiceOpcao5} para idade gestacional.`
      );
    if (
      this.laudo.arteriasUterinas &&
      this.laudo.arteriasUterinas ==
        'Artérias uterinas com média do Índice de pulsatilidade'
    )
      folha.push(
        ` Artérias uterinas com média do Índice de pulsatilidade ${this.laudo.arteriasUterinas2} para idade gestacional. ${this.laudo.arteriasUterinas3} o risco de restrição de crescimento fetal e pré-eclâmpsia.`
      );
    if (this.laudo.velocidadeAcm) folha.push(`${this.laudo.velocidadeAcm}.`);
    if (this.laudo.comentariosAdicionais)
      folha.push(`${this.laudo.comentariosAdicionais}`);
    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [
   
      ...this.arteriaUmbilical,
      ...this.arteriaCerebralMedia,
      ...this.arteriaUterinaDireita,
      ...this.arteriaUterinaEsquerda,
      ...this.ductoVenoso,
      ...this.conclusao,
    ];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 40));
    }
  },
  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },

  data() {
    return {
      arteriaUmbilical: [],
      arteriaCerebralMedia: [],
      arteriaUterinaDireita: [],
      arteriaUterinaEsquerda: [],
      conclusao: [],
      tamanhoElemento: [],
      titulos: [
        'Artéria umbilical',
        'Artéria cerebral média',
        'Artéria Uterina direita',
        'Artéria Uterina esquerda',
        'Artéria uterina',
        'Ducto Venoso',
        'Conclusão',
        'História materna'
      ],
    };
  },
  computed: {
    mediaIndice() {
      if (
        !this.laudoSelecionado ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
      )
        return '';
      return (
        (parseFloat(
          this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
        ) +
          parseFloat(
            this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
          )) /
        2.0
      ).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: -5px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: -5px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -3px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
}
</style>
